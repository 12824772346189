<template>
  <CContainer class=" ">
    <CAlert
      :show.sync="dismissCountDown"
      closeButton
      color="danger"
      fade
    >
      {{message.message}}
    </CAlert>
    <CRow class="justify-content-center d-flex align-items-center min-vh-100">
      <CCol md="6" class="mx-auto">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <form name="form" @submit.prevent="handleLogin">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <CInput
                  v-model="user.username"
                  placeholder="Username"
                  required
                  autocomplete="username email"
                  name="username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  v-model="user.password"
                  placeholder="Password"
                  type="password"
                  required
                  autocomplete="curent-password"
                  name="password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton type="submit" color="primary" class="px-4" :disabled="loading" >Login</CButton>
                  </CCol>

                  <!-- <div class="form-group">
                    <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
                  </div> -->
                  <!-- <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Forgot password?</CButton>
                  </CCol> -->
                </CRow>
              </form>
            </CCardBody>
          </CCard>
          
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import User from "../../models/user.js";

export default {
  name: 'Login',
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('dashboard');
    }
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    showDismissibleAlerts () {
      ['alert1', 'alert2', 'alert3'].forEach(alert => this[alert] = true)
    },
    handleLogin() {
      this.loading = true;
      

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('dashboard');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
              this.dismissCountDown= 10
            }
          );
        }

    }
  }
};
</script>
